import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout, Container } from 'layout';
import BreadCrumbs from 'common/BreadCrumbs';
import SecondaryNavigation from 'components/SecondaryNavigation';
import ArticleCardsList from 'components/ArticleCardsList';
import HeroBanner from 'components/HeroBanner';

import { schemaService } from 'utils/schemaService';

import { IPropsArticleLandingPage } from './models';

import './ArticleLandingPage.scss';
import './ShadowArticleLandingPage.scss';

const ArticleLandingPage: FC<IPropsArticleLandingPage> = ({
  pageContext: { breadcrumbs, link },
  data: {
    articleLandingPage: {
      seo,
      defaultCompositions,
      heroBanner,
      secondaryNavigation,
      loadMoreArticleCardsText,
      loadMoreArticleCardsTextAriaLabel,
      tags,
      showMoreButtonAriaLabel,
    },
    relatedArticles,
  },
}) => (
  <Layout
    defaultCompositions={defaultCompositions}
    seo={seo}
    anefpBlockCustomization={{ isHideBlock: true }}
    className="article-landing-page"
    additionalStructuredSchemas={[schemaService.schemaTypes.BreadcrumbList]}
    dynamicStructuredSchemaValues={{ pageUrl: link, breadcrumbs }}
  >
    <Container>
      <BreadCrumbs breadcrumbs={breadcrumbs} />
    </Container>
    {heroBanner ? <HeroBanner heroBanner={heroBanner} hideOnMobile /> : null}
    <SecondaryNavigation secondaryNavigation={secondaryNavigation} tags={tags} />
    <ArticleCardsList
      cards={relatedArticles?.nodes || []}
      loadMoreArticleCardsText={loadMoreArticleCardsText}
      loadMoreArticleCardsTextAriaLabel={loadMoreArticleCardsTextAriaLabel}
      showMoreButtonAriaLabel={showMoreButtonAriaLabel}
    />
  </Layout>
);

export const query = graphql`
  query ArticleLandingPage($link: String = "", $tags: [Int], $lang: String!) {
    articleLandingPage(link: { eq: $link }) {
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      heroBanner {
        ...FragmentHeroBanner
      }
      secondaryNavigation {
        properties {
          title
          alpSecondaryNavigationTags {
            properties {
              tagsContent {
                title
                url {
                  url
                }
              }
              articleTagOrder
            }
          }
        }
      }
      tags {
        ...FragmentArticleCategoryTag
      }
      loadMoreArticleCardsText
      loadMoreArticleCardsTextAriaLabel
      showMoreButtonAriaLabel
    }
    relatedArticles: allArticle(
      filter: { tags: { elemMatch: { id: { in: $tags } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
  }
`;

export default ArticleLandingPage;
